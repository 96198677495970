<div class="ngxdt audit-logs">
    <div class="ngxdt-header active-log px-2">
    </div>

    <div class="ngxdt-body">
        <ngx-datatable #table class="bootstrap" 
         [headerHeight]="50"
         [footerHeight]="50"
         [rowHeight]="'auto'"
         [messages]="{
           emptyMessage: ngxDatatableSSRConfig.emptyMessage,
           totalMessage: ngxDatatableSSRConfig.totalMessage,
           selectedMessage: false
         }" 
         [footerHeight]="ngxDatatableSSRConfig.footerHeight" 
         [limit]="ngxDatatableSSRConfig.pageSize" 
         [rows]="rows"
         [columnMode]="'flex'" 
         [offset]="ngxDatatableSSRConfig.tableOffset" 
         [loadingIndicator]="false"
         [scrollbarH]="true"
         [scrollbarV]="true"
         [selectionType]="'row'"
         [virtualization]="false">
            <ngx-datatable-column *ngFor="let column of columns"
                [flexGrow]="column.flexGrow"
                [minWidth]="column.minWidth" 
                [prop]="column.prop"
                [name]="column.name"
                [sortable]="false"
                [resizable]="false"
                [draggable]="false"
                >
                <!-- [sortable]="column.sortable"
                [draggable]="column.draggable" -->
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <div *ngIf="!column.cellTemplate" class="text-truncate overflow-hidden">{{ value }}</div>
                    <div *ngIf="column.cellTemplate">
                        <app-edit-delete-icon [row]="row" [column]="column"
                         (onChanged)="onChanged($event)" 
                         (deletedRowData)="onDelete($event)"
                         (editedRowData)="editedRowData($event)">
                        </app-edit-delete-icon>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template *ngIf="footerShow" ngx-datatable-footer-template>
                    <div>
                        <div class="page-size-control p-t-7px">
                            <div class="text-nowrap f-11 text-black-color"> Showing <span class="font-semibold">1</span> to <span
                                    class="font-semibold">
                                    <select class="page-size-dropdown" [(ngModel)]="ngxDatatableSSRConfig.pageSize"
                                        (ngModelChange)="onPageSizeChanged($event)">
                                        <option *ngFor="let opt of ngxDatatableSSRConfig.limitOptions" [ngValue]="opt.value">
                                            {{opt.key}}
                                        </option>
                                    </select>
                                </span> of <span class="font-semibold">{{ ngxDatatableSSRConfig?.totalRecords}}</span> entries</div>
                        </div>
                        <div class="pagination-control pe-5">
                            <ngb-pagination #ngbPage [boundaryLinks]="false" [collectionSize]="ngxDatatableSSRConfig.totalRecords"
                                [pageSize]="ngxDatatableSSRConfig.pageSize == 1?ngxDatatableSSRConfig.totalRecords:ngxDatatableSSRConfig.pageSize"
                                [page]="ngxDatatableSSRConfig.currentPageNumber" [maxSize]="5" (pageChange)="onPageChanged($event)">
                                <ng-template ngbPaginationPrevious>
                                    <img width="12" height="12" src="./../../../../assets/svg/LeftArrow.svg" alt="">
                                </ng-template>
                                <ng-template ngbPaginationNext>
                                    <img width="12" height="12" src="./../../../../assets/svg/RightArrow.svg" alt="">
                                </ng-template>
                            </ngb-pagination>
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-footer>

        </ngx-datatable>
    </div>
    <div class="ngxdt-footer">
    </div>
</div>