import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { StorageName } from "src/app/shared/enum/common-enum";
import { StorageService } from "src/app/shared/services/storage.service";
import { LoginService } from "./login.service";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;
  // email: "admin@digitalsalon.com",
  // password: "DSalon@1234#",

  constructor(private fb: FormBuilder, public router: Router,
    private storageservice  : StorageService,
    private toster: ToastrService,
    public loginService: LoginService) {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
      rememberMe: true

    });
  }

  ngOnInit() {}
  login(){
    let user = {
      email: this.loginForm.get('email')?.value,
      password: this.loginForm.get('password')?.value,
      rememberMe: this.loginForm.get('rememberMe')?.value
    };
    this.loginService.signIn(user).subscribe((res=>{
      if(res){
        this.storageservice.store(StorageName.STORED_USER_DETAILS, res?.response);
        this.toster.success(res?.message);
        this.router.navigate(["/dashboard"]);

      }else{
        this.toster.error(res?.message)
      }
    }))
  }
  onChange(value){
   console.log(value);

  }
  showPassword(){
    this.show = !this.show
  }
}
