import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Status } from '../../enum/common-enum';

@Component({
  selector: 'app-edit-delete-icon',
  templateUrl: './edit-delete-icon.component.html',
  styleUrl: './edit-delete-icon.component.scss'
})
export class EditDeleteIconComponent implements OnInit {
  @Input() row;
  @Input() column
  @Output() onChanged = new EventEmitter<any>();
  @Output() editedRowData = new EventEmitter<any>();
  @Output() deletedRowData = new EventEmitter<any>();

  status = Status
  onStatusChanged(row, checked) {
    row.toggledTab = checked;
    const request = {
      userId: row.id,
      statusId: row.toggledTab ? Status.active : Status.deactive,
    }
    this.onChanged.emit(request);
  }

  ngOnInit(): void {
  }
  onButtonClick(row,value){

  }
  onEditClick(row){
    this.editedRowData.emit(row);

  }
  onDeleteClick(row){
    this.deletedRowData.emit(row);
  }
}
