import { Routes } from "@angular/router";


export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "salonList",
    loadChildren: () => import("../../components/salon-lists/salon-lists.module").then((m) => m.SalonListsModule),
  },
  {
    path: "settings",
    loadChildren: () => import("../../components/settings/settings.module").then((m) => m.SettingsModule),
  },

  


  
];

