import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { DataService  } from "../../shared/services/data.service";
import {ResponseModel} from "../../shared/model/response.model"

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(public service: DataService) {
  }

  signIn(data: any) {
    let url = 'api/admin/adminsignin';
    return this.service.post(url, data, false).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

}