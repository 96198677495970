import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';

export class NgxDatatableSSRConfig {
  rows: any[] = [];
  totalRecords: number = 0;
  pageSize: number = 25;
  getAll:boolean= false;

  currentPageNumber: number = 1;
  tableOffset: number = 0;

  searchText: string = "";

  columnMode = ColumnMode;
  selectionType = SelectionType;

  footerHeight: any = 30;
  headerHeight : any = "auto";
  rowHeight: any = "auto";

  emptyMessage: string = 'No data found.';
  totalMessage: string = 'Total';
  selectedMessage: any = false;  

  limitOptions = [
    {
      key: '10',
      value: 10
    },
    {
      key: '25',
      value: 25
    },
    {
      key: '50',
      value: 50
    },
    {
      key: '75',
      value: 75
    },
    {
      key: '100',
      value: 100
    },

    {
      key: 'All',
      value: 1
    },
  ];

  public onPageChanged(pageNum: number) {
    this.currentPageNumber = pageNum;
    this.tableOffset = pageNum - 1;
  }

  public onPageSizeChanged(pageSize: number) {
    this.getAll = pageSize == 1;
    this.pageSize = pageSize;
    this.onPageChanged(1);
  }

  public onSort(pageNum: number) {
    this.onPageChanged(1);
  }

  public updateTableData(response:any){
    this.rows = response?.response;
    this.totalRecords = response?.totalRecords;
  }

  public updateTableDataClientSide(response:any[]){
    this.rows = response;
    this.totalRecords = this.rows?.length;
  }
}
