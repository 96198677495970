import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

export class Common {
}

  export const commonModal: NgbModalOptions = {
    centered        : true,
    backdrop        : "static",
    keyboard        : false,
    size            : "md",
  };
  
  export const ModalPopupSize = {
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
    XL: 'xl',
    XXL: 'xxl',
  }
  