<div  class="form-check mb-0 d-flex align-items-center">
  <input
    class="form-check-input my-0"
    type="checkbox"
    value=""
    role="checkbox"
    [checked]="status"
    [disabled]="disabled"
    [readonly]="readonly"
    (click)="check.emit($event.target.checked)"
    id="{{ dyanamicId ? dyanamicId : lableName }}"
    [name]="dynamicName"
  />
  <label
    class="form-check-label mb-0 font-12 text-truncate  {{readonly ? 'non-editable cursor-default' : 'cursorPointer'}}"
    [ngClass]="lableClass"
    for="{{ dyanamicId ? dyanamicId : lableName }}"
  >
    {{ lableName }}
  </label>
</div>
