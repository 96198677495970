
<button
  class="btn btn-sm" 
  [class]="buttonClass"
  [type]="buttonType"
  [ngClass]="ngClass"
  [disabled]="inputReadonly">
<span class="my-auto">{{ buttonLable }}</span>
</button>

