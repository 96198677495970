export enum StorageName {
    STORED_ACTIVE_SALONS = "STORED_ACTIVE_SALONS",
    STORED_USER_DETAILS = "STORED_USER_DETAILS",
    SETTINGS_DETAILS = "SETTINGS_DETAILS"

}
export enum Status {
    active = 1,
    deactive = 2
}
export enum URLS {
    freeSalons = '/salonList/freeSalons',
    premiumSalons = '/salonList/premiumSalons',
    activeSalons = '/salonList/activeSalons',
    deactivateSalons = '/salonList/deactivateSalons',
}
export enum VideoType {
    HomepageVideos = 1,
    VideoInstructionPage = 2,
}
export enum VideoLabel {
    LabelHomepageVideos = "Homepage Videos",
    LabelVideoInstructionPage = "Video Instruction Page",
}

