import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable,} from 'rxjs';
import { commonModal, ModalPopupSize } from '../model/common.model';

@Injectable({
  providedIn: 'root'
})
export class SendDataService {

  
  public headerPageName = new BehaviorSubject<any>(undefined);
  private tablePagination = new BehaviorSubject<any>(undefined);


  setPaginationData(data: any): void {
    this.tablePagination.next(data);
  }


  getPaginationData(): Observable<any> {
    return this.tablePagination.asObservable();
  }

  constructor(private modalService: NgbModal , public toaster?:ToastrService ,public router?: Router) { 
  }
  openModalPopup(component: any, data: any, title: string, popupSize: string = ModalPopupSize.LG, windowClass: string = "", fullscreen?: boolean, centered?: boolean): Promise<any> {
    commonModal.size = popupSize;
    commonModal.windowClass = windowClass;
    commonModal.centered = centered??true;

    const modalOption: NgbModalOptions = {
      ...commonModal,
    };
    const modalRef = this.modalService.open(component, modalOption);
    if (title != undefined)
      modalRef.componentInstance.title = title;
    modalRef.componentInstance.data = data;
    return modalRef.result.then(
      (result) => {
        if (result) {
          return result; // Returning the result back to the calling method
        }
      },
      (reason) => {
        // Handle dismissal case if needed
        return null;
      }
    );
  }

}
