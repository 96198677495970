import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],

})
export class InputComponent {
  @Input() inputType:string='text';
  @Input() inputName:string;
  @Input() inputPlaceholder:string='';
  @Input() inputLabel:string;
  @Input() inputId:string;
  @Input() inputValue:string='';
  @Input() isRequired:boolean;
  @Input() inputMaxlength:boolean=false;
  @Input() inputClass:string='';
  @Input() disabled :boolean;
  @Input() readOnly: boolean=false;
  @Input() max:any;
  @Input() min:any;
  @Input() maxLength:any;
  @Input() minLength:any;
  @Output() inputValueEmit:EventEmitter<string> = new EventEmitter();
  @Output() keydownEmit:EventEmitter<string> = new EventEmitter();
  @Output() inputOnBlurEmit:EventEmitter<any> = new EventEmitter();
  @Input() errorMessage: string 



  focusInput(event){
  }

  onBlur() {
   
  }
}
