import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxDatatableSSRConfig } from '../../model/ngx-datatable-ssr-config.model';
import { SalonListsService } from 'src/app/components/salon-lists/salon-lists.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { catchError } from 'rxjs';
import { ResponseModel } from '../../model/response.model';

@Component({
  selector: 'app-ngx-datatable',
  templateUrl: './ngx-datatable.component.html',
  styleUrl: './ngx-datatable.component.scss'
})
export class NgxDatatableComponent {
  ngxDatatableSSRConfig = new NgxDatatableSSRConfig();

  @Input()  rows:any = [];
  @Input()   columns:any = [];
  @Output() changed = new EventEmitter<boolean>();
  @Output() onPagination: EventEmitter<NgxDatatableSSRConfig> = new EventEmitter();
  @Output() onPageSizeUpdated: EventEmitter<number> = new EventEmitter();
  @Output() statusChanged = new EventEmitter<any>();
  @Input()   footerShow:boolean = true;
  @Output() onEditClick = new EventEmitter<any>();
  @Output() onDeleteClick = new EventEmitter<any>();

  
  constructor(
    public router:Router,
    public salonListsService:SalonListsService,
    public toaster:ToastrService
    ){}

  ngOnInit(){
   this.initDatatable();
  }
  initDatatable(): void {
    this.rows = [];
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
  }

  onPageSizeChanged(pageSize: number) {
    this.rows = [];
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);
    this.onPagination.emit(this.ngxDatatableSSRConfig)  
  }

  onPageChanged(pageNum: number) {
    this.rows = [];
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);
    this.onPagination.emit(this.ngxDatatableSSRConfig)  
  }
  search(){
    this.rows = [];
    this.ngxDatatableSSRConfig.onPageChanged(1);
    this.onPagination.emit(this.ngxDatatableSSRConfig)  
  }
  updatePaginationData(data,ngxDatatableSSRConfig) {
    this.ngxDatatableSSRConfig.currentPageNumber = ngxDatatableSSRConfig?.pageNumber;
    this.ngxDatatableSSRConfig.totalRecords = data?.totalRecords;
  }
  onChanged(event){
  this.statusChanged.emit(event)
  }
  handleError(err: any): any {
    throw new Error('Method not implemented.');
  }
  editedRowData(event){
    this.onEditClick.emit(event)
    }
    onDelete(event){
      this.onDeleteClick.emit(event)
    }
}
