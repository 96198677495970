<button id="hidden" class="position-absolute top-0 end-0 opacity-0"></button>

<div class="d-flex gap-3 commonInputContainer align-items-center w-100">
  <label *ngIf="inputLabel" [for]="id" class="text-nowrap mb-0 w-50">{{ inputLabel }}</label>
  <input 
  [type]="inputType" 
  [id]="inputId" 
  [name]="inputName" 
  [placeholder]="inputPlaceholder"
  [maxlength]="inputMaxlength"
  [(ngModel)]="inputValue" 
  [class]="inputClass +' form-control'"
  (ngModelChange)="inputValueEmit.emit(inputValue)"
  [required]="isRequired"
  [disabled]="disabled"
  (focus)="focusInput($event)"
  (blur)="inputOnBlurEmit.emit(inputValue)"
  [maxlength]="maxLength"
  [minlength]="minLength"
  [readonly]="readOnly"
  />
  <small *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</small>
</div>

