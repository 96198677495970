import { Injectable } from '@angular/core';
import { DataService  } from "../../shared/services/data.service";
import { Observable, tap } from 'rxjs';
import {ResponseModel} from "../../shared/model/response.model"

@Injectable({
  providedIn: 'root'
})
export class SalonListsService {

  constructor(private service: DataService) {}
  getAllUserList(data: any): Observable<ResponseModel> {
    let url = 'api/admin/getallsalaonlist';    
    return this.service.post(url, data).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  getAllDashbordList(): Observable<ResponseModel> {
    let url = 'api/admin/getalldashboardlist';    
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  updateUserStatus(data: any): Observable<ResponseModel> {
    let url = 'api/admin/updateuserstatusbyadmin';    
    return this.service.put(url,data).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  getAllFreeSalonList(data: any): Observable<ResponseModel> {
    let url = 'api/admin/getallfreesalonlist';    
    return this.service.post(url, data).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  getAllPremiumSalonList(data: any): Observable<ResponseModel> {
    let url = 'api/admin/getallpremiumsalonlist';    
    return this.service.post(url, data).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }


}
