import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent implements OnInit {
  @Input() buttonLable  : any;
  @Input() buttonClass:string;
  @Output("buttonOutputEvent") buttonOutputEvent: EventEmitter<any> = new EventEmitter();
  @Input('ngClass') ngClass: NgClass["ngClass"];
  @Input() inputReadonly : boolean  = false;
  @Output() iconClick = new EventEmitter<void>();
  @Input() buttonType:string;

  constructor() { }

  onButtonClick(value: string) 
  {
    this.buttonOutputEvent.emit(value);
  }

  ngOnInit(): void {
  }

  onIconClick(event : MouseEvent): void {
    event.stopPropagation();
    this.iconClick.emit();
  }
}