<div class="cart-box" (click)="toggleCart()">
  <!-- <svg>
    <use href="assets/svg/icon-sprite.svg#stroke-ecommerce"></use>
  </svg> -->
  <svg xmlns="http://www.w3.org/2000/svg" width="20.283" height="18.287" viewBox="0 0 20.283 18.287">
    <g id="Group_28" data-name="Group 28" transform="translate(-1.735 -2.978)">
      <path id="Path_382" data-name="Path 382" d="M3,20l1.3-3.9A7.417,7.417,0,0,1,6.4,5.726a9.856,9.856,0,0,1,11.846.48,7.362,7.362,0,0,1,1.029,10.5A9.733,9.733,0,0,1,7.7,19L3,20" fill="none" stroke="#7e84a3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <line id="Line_15" data-name="Line 15" y2="0.01" transform="translate(12 12)" fill="none" stroke="#7e84a3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <line id="Line_16" data-name="Line 16" y2="0.01" transform="translate(8 12)" fill="none" stroke="#7e84a3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <line id="Line_17" data-name="Line 17" y2="0.01" transform="translate(16 12)" fill="none" stroke="#7e84a3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </svg>
      

  <span class="badge rounded-pill badge-primary">2</span>
</div>
<div class="cart-dropdown onhover-show-div" [class.active]="openCart">
  <h6 class="f-18 mb-0 dropdown-title">Cart</h6>
  <ul>
    <li>
      <div class="media">
        <img class="img-fluid b-r-5 me-3 img-60" src="assets/images/other-images/cart-img.jpg" alt="" />
        <div class="media-body">
          <span>Furniture Chair for Home</span>
          <div class="qty-box">
            <div class="input-group">
              <span class="input-group-prepend"> <button class="btn quantity-left-minus" type="button" data-type="minus" data-field="">-</button></span> <input class="form-control input-number" type="text" name="quantity" value="1" /><span class="input-group-prepend">
                <button class="btn quantity-right-plus" type="button" data-type="plus" data-field="">+</button></span
              >
            </div>
          </div>
          <h6 class="font-primary">$500</h6>
        </div>
        <div class="close-circle">
          <a class="bg-danger" href="#"><app-feather-icons [icon]="'x'"></app-feather-icons></a>
        </div>
      </div>
    </li>
    <li>
      <div class="media">
        <img class="img-fluid b-r-5 me-3 img-60" src="assets/images/other-images/cart-img.jpg" alt="" />
        <div class="media-body">
          <span>Furniture Chair for Home</span>
          <div class="qty-box">
            <div class="input-group">
              <span class="input-group-prepend"> <button class="btn quantity-left-minus" type="button" data-type="minus" data-field="">-</button></span> <input class="form-control input-number" type="text" name="quantity" value="1" /><span class="input-group-prepend">
                <button class="btn quantity-right-plus" type="button" data-type="plus" data-field="">+</button></span
              >
            </div>
          </div>
          <h6 class="font-primary">$500.00</h6>
        </div>
        <div class="close-circle">
          <a class="bg-danger" href="#"><app-feather-icons [icon]="'x'"></app-feather-icons></a>
        </div>
      </div>
    </li>
    <li class="total">
      <h6 class="mb-0">Order Total : <span class="f-right">$1000.00</span></h6>
    </li>
    <li class="text-center"><a class="d-block mb-3 view-cart f-w-700" href="cart.html">Go to your cart</a><a class="btn btn-primary view-checkout" href="checkout.html">Checkout</a></li>
  </ul>
</div>
