<select  
    [id]="id" 
    [name]="name" 
    [tabIndex]="'0'"
    [required]="isRequired"    
    [disabled]="isDisabled"
    [class]="selectClass +' form-select pe-4 text-truncate'" 
    [(ngModel)]="selectedValue"
    (ngModelChange)="valueChanged.emit($event)"
    [ngClass]="{'readonly-field': isDisabled}"
    #selectInput="ngModel"
>
      <ng-container>
        <option *ngIf="defaultOption" value="0"></option>
        <option *ngFor="let option of options" [value]="option.id">
            {{option.name}}
        </option>
    </ng-container>

</select>
