<div *ngIf="column?.prop == 'userStatus'" class="toggle_btn">
    <input  [checked]="row?.toggledTab"  type="checkbox"
    [id]="'toggle-button-checkbox-' + row?.srNo" 
   (change)="onStatusChanged(row,$event.target.checked)">
    <label class="toggle-button-switch" [for]="'toggle-button-checkbox-' + row?.srNo">
      <img [ngClass]="{'d-none': !row?.toggledTab, 'd-block': row?.toggledTab}" class="img-right" width="10" height="10" src="../../../../../../assets/svg/right.svg" alt="">
      <img  [ngClass]="{'d-none': row?.toggledTab, 'd-block': !row?.toggledTab}"  class="img-right" width="10" height="10" src="../../../../../../assets/svg/cross.svg" alt="">
    </label>
    <div class="toggle-button-text">
        <div [ngClass]="{'d-none': row?.toggledTab, 'd-block': !row?.toggledTab}" class="toggle-button-text-off pe-2">Deactivate</div>
        <div [ngClass]="{'d-none': !row?.toggledTab, 'd-block': row?.toggledTab}"  class="toggle-button-text-on ps-2">Active</div>
    </div>
</div>
<div *ngIf="column?.prop == 'action'">
    <button class="btn btn_preview w-auto ms-2 mb-2" (click)="onButtonClick(row,true)">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 150.78 90.915">
            <path id="Path_4751" data-name="Path 4751"
                d="M160.78,102.034c0,14.631-31.114,45.681-75.39,45.681-43.381,0-75.39-30.6-75.39-45.681S42.52,56.8,85.39,56.8c44.787,0,75.39,30.156,75.39,45.234ZM85.39,64.786C65.137,64.786,49.1,81.27,49.1,102.034c0,20.7,16.484,37.248,36.289,37.248,20.253,0,36.289-16.484,36.289-37.248S105.643,64.786,85.39,64.786Zm0,13.672a23.581,23.581,0,1,0,23.064,23.575A23.21,23.21,0,0,0,85.39,78.459Z"
                transform="translate(-10 -56.8)" fill="#fff" />
        </svg>
    </button>
      <!-- <button class="btn btn_delete w-auto ms-2 mb-2" (click)="onButtonClick(row,false)">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 123.1 138.4">
            <path id="Path_4750" data-name="Path 4750" d="M174,181.8c-1.9,8.6-6.7,15.4-14.9,15.4H99.4c-8.2,0-13-6.7-14.9-15.4l-.4-88.4h90.3ZM189.5,77.6a5.228,5.228,0,0,1-5.2,5.2H71.6a5.228,5.228,0,0,1-5.2-5.2v-3a5.228,5.228,0,0,1,5.2-5.2H97.5a11.852,11.852,0,0,0,8.2-4.1l1.8-2.4a11.469,11.469,0,0,1,8.2-4.1h27.1a11.852,11.852,0,0,1,8.2,4.1l1.8,2.4a11.469,11.469,0,0,0,8.2,4.1h23.3a5.228,5.228,0,0,1,5.2,5.2v3Z" transform="translate(-66.4 -58.8)" fill="#fff"/>
          </svg>
      </button> -->
    </div>

    <div class="" *ngIf="column?.prop == 'edit'">
      <div class="pt-3 d-flex gap-4">
        <svg class="cursorPointer" (click)="onEditClick(row)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" id="editIcon">
          <g id="edit" transform="translate(0 -0.004)">
            <g id="Group_1317" data-name="Group 1317" transform="translate(0 1.339)">
              <g id="Group_1316" data-name="Group 1316">
                <path id="Path_987" data-name="Path 987" d="M16.608,42.752a.72.72,0,0,0-.722.718v7.179a.72.72,0,0,1-.722.718h-13a.72.72,0,0,1-.722-.718V36.291a.72.72,0,0,1,.722-.718h8.665a.718.718,0,1,0,0-1.436H2.166A2.16,2.16,0,0,0,0,36.291V50.648A2.16,2.16,0,0,0,2.166,52.8h13a2.16,2.16,0,0,0,2.166-2.154V43.469A.72.72,0,0,0,16.608,42.752Z" transform="translate(0 -34.137)" fill="#8b9daa"/>
              </g>
            </g>
            <g id="Group_1319" data-name="Group 1319" transform="translate(5.316 0.004)">
              <g id="Group_1318" data-name="Group 1318" transform="translate(0 0)">
                <path id="Path_988" data-name="Path 988" d="M116.344.752a2.555,2.555,0,0,0-3.614,0l-8.792,8.791a.675.675,0,0,0-.161.261l-1.335,4a.667.667,0,0,0,.633.878.678.678,0,0,0,.211-.034l4-1.335a.668.668,0,0,0,.261-.162l8.791-8.791A2.555,2.555,0,0,0,116.344.752Zm-.944,2.67L106.723,12.1l-2.592.866.863-2.589,8.68-8.677A1.22,1.22,0,1,1,115.4,3.422Z" transform="translate(-102.409 -0.004)" fill="#8b9daa"/>
              </g>
            </g>
          </g>
        </svg>
        <svg class="cursorPointer"  (click)="onDeleteClick(row)" id="delete_1_" data-name="delete (1)" xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 12 15">
          <path id="Path_983" data-name="Path 983" d="M222.757,154.7a.359.359,0,0,0-.359.359v6.781a.359.359,0,0,0,.718,0v-6.781A.359.359,0,0,0,222.757,154.7Zm0,0" transform="translate(-214.419 -149.153)" fill="#cc4d4d"/>
          <path id="Path_984" data-name="Path 984" d="M104.757,154.7a.359.359,0,0,0-.359.359v6.781a.359.359,0,0,0,.718,0v-6.781A.359.359,0,0,0,104.757,154.7Zm0,0" transform="translate(-100.653 -149.153)" fill="#cc4d4d"/>
          <path id="Path_985" data-name="Path 985" d="M.98,4.464v8.655a1.955,1.955,0,0,0,.508,1.337A1.692,1.692,0,0,0,2.723,15H9.27a1.692,1.692,0,0,0,1.236-.543,1.955,1.955,0,0,0,.508-1.337V4.464a1.344,1.344,0,0,0-.339-2.639H8.9V1.386A1.391,1.391,0,0,0,8.5.4,1.349,1.349,0,0,0,7.533,0H4.461A1.349,1.349,0,0,0,3.49.4a1.391,1.391,0,0,0-.4.985v.439H1.319A1.344,1.344,0,0,0,.98,4.464ZM9.27,14.3H2.723a1.106,1.106,0,0,1-1.052-1.177V4.5h8.65v8.624A1.106,1.106,0,0,1,9.27,14.3ZM3.782,1.386A.682.682,0,0,1,3.979.9.661.661,0,0,1,4.461.7H7.533a.661.661,0,0,1,.482.2.681.681,0,0,1,.2.488v.439H3.782ZM1.319,2.528h9.356a.632.632,0,0,1,0,1.265H1.319a.632.632,0,0,1,0-1.265Zm0,0" transform="translate(0.003 0.001)" fill="#cc4d4d"/>
          <path id="Path_986" data-name="Path 986" d="M163.757,154.7a.359.359,0,0,0-.359.359v6.781a.359.359,0,0,0,.718,0v-6.781A.359.359,0,0,0,163.757,154.7Zm0,0" transform="translate(-157.536 -149.153)" fill="#cc4d4d"/>
        </svg>
        
  
      </div>
      </div>
      <div class="" *ngIf="column?.prop == 'isActive'">
        <div class="pt-3 d-flex">
          <input 
          [style.height]="'17px'"
          [style.width]="'17px'"
          type="checkbox"
          [checked]="row?.isActive"
          (click)="$event.preventDefault()">
        </div>
        </div>
        <div class="" *ngIf="column?.prop == 'thumbnailUrl'">
          <div *ngIf="row?.thumbnailUrl">
            <img  class="img_box"
            [src]="row?.thumbnailUrl"
            alt="">
          </div>
          <span  *ngIf="!row?.thumbnailUrl">N/A</span>
          </div>
  

  
      
  
  

